<template>
  <div class="hccs">
    <b-container fluid="xxl">
      <b-row>
        <b-col cols="4">
          <HeadAutocomplete ref="head" :head="head" :disabled="disableHead" />
        </b-col>
      </b-row>
      <b-row>
        <b-col id="select-hcc">
          <b-form-group
            id="hcc"
            label="Head Category Configuration:"
            label-for="hcc"
          >
            <Select2 @select="select($event)" :settings="selectConfig" ref="select2" tabindex="0" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
  * >>> .container-xxl {
    padding: 0;
  }
</style>

<script>
import HelpStore from "@/store/helpstore.js";
import Select2 from 'v-select2-component';
import config from '@/config.js';
import HeadAutocomplete from '@/components/Scripta/HeadAutocomplete.vue'

export default {
  name: "HCCAutocomplete",
  props: ["exclude", "hcc", "head"],
  data() {
    return {
      query: "",
      // disableHead: this.head && this.disableSelectHead != false ? true : false,
      disableHead: false,
      hccObj: this.hcc,
      // ordering: this.hcc ? this.hcc.ordering : "",
      index: this.hcc ? this.hcc._index : null,
    };
  },
  components: {
    Select2,
    HeadAutocomplete,
  },
  methods: {
    select(event){
      this.hccObj = event.selected ? event.hcc : null
      this.update()
      this.$emit('select', this.hccObj)
    },
    update(){
      this.hccObj["_index"] = this.index;
      HelpStore.item = this.hccObj;
    },
  },
  updated() {
    this.update()
  },
  mounted() {
    if (this.hcc){
      this.$refs.select2.select2.append(
        `<option selected value="${this.hcc.id}">${this.hcc.code}</option>`
      ).trigger('change')
    }
  },
  computed: {
    selectConfig(){
      return {
        placeholder: "Select a HCC",
        allowClear: this.allowClear == false ? false : true,
        width: '100%',
        dropdownParent: "#select-hcc",
        ajax: {
          delay: 250,
          url: config.apiUrl + '/hccs/autocomplete',
          headers: config.apiHeaders,
          data: (params) => {
            return {
              query: params.term,
              head: this.$refs.head.headObj ? this.$refs.head.headObj.id : null,
            }
          },
          processResults: (data) => {
            return {
              results: data.map(hcc => {
                return {
                  id: hcc.id,
                  text: `${hcc.description} (${hcc.code})`,
                  hcc: hcc
                }
              })
            }
          }
        }
      }
    }
  },
};
</script>
