<template>
  <div class="heads">
    <b-container fluid="xxl">
      <b-row>
        <b-col id="select-head">
          <b-form-group
            id="head"
            label="Head:"
            label-for="head"
          >
            <Select2 @select="select($event)" :settings="selectConfig" ref="select2" tabindex="0" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import HelpStore from "@/store/helpstore.js";
import Select2 from 'v-select2-component';
import config from '@/config.js';

export default {
  name: "HeadAutocomplete",
  props: ["exclude", "head", "disabled", "parentId"],
  data() {
    return {
      query: "",
      timer: null,
      headObj: this.head,
      parentIdElem: this.parentId ? this.parentId : "select-head",
      index: this.head ? this.head._index : null,
      selectDisabled: this.disabled == true ? true : false,
    };
  },
  components: {
    Select2,
  },
  methods: {
    select(event){
      this.headObj = event.selected ? event.head : null
      this.$emit("select", this.headObj)
      this.update()
    },
    update(){
      this.headObj["ordering"] = this.ordering;
      this.headObj["_index"] = this.index;
      HelpStore.item = this.headObj;
    },
  },
  updated() {
    this.update()
  },
  mounted() {
    if (this.head){
      this.$refs.select2.select2.append(
        `<option selected value="${this.head.id}">${this.head.form}</option>`
      ).trigger('change')
    }
  },
  computed: {
    selectConfig(){
      return {
        placeholder: "Select a head",
        // allowClear: this.allowClear == false ? false : true,
        width: '100%',
        disabled: this.selectDisabled,
        dropdownParent: `#${this.parentIdElem}`,
        ajax: {
          delay: 250,
          url: config.apiUrl + '/heads/autocomplete',
          headers: config.apiHeaders,
          data: (params) => {
            return {
              query: params.term
            }
          },
          processResults: (data) => {
            return {
              results: data.map(head => {
                return {
                  id: head.id,
                  text: head.form,
                  head: head
                }
              })
            }
          }
        }
      }
    }
  },
};
</script>
