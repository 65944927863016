<template>
  <div>
    <div class="mb-2">
      <b-button size="sm" variant="info" @click="downloadCsvSelected" class="mr-1" :disabled="selectedTokensLemmas.length == 0">
        Download Selected Occurrences (.csv)
      </b-button>
    </div>
    <div class="mb-5">
      <span v-if="config.editable">
        <b-button size="sm" variant="secondary" v-b-modal.modal-lemmatize class="mr-1" :disabled="selectedTokensLemmas.length == 0">
          + Lemma
        </b-button>
        <b-button size="sm" variant="danger" class="mr-2" :disabled="selectedTokensLemmas.length == 0" @click="removeLemma">
          Remove Lemma
        </b-button>
        <b-button size="sm" variant="secondary" v-b-modal.modal-tagging class="mr-1" :disabled="selectedTokensLemmas.length == 0">
          + HCC
        </b-button>
        <b-button size="sm" variant="secondary" v-b-modal.modal-tagging-replace class="mr-1" :disabled="selectedTokensLemmas.length == 0" v-if="hcc">
          Replace HCC
        </b-button>
        <!-- <b-button size="sm" variant="danger" class="mr-2" :disabled="selectedTokensLemmas.length == 0" @click="removeTag">
          Remove Tag
        </b-button> -->
        <b-button size="sm" variant="danger" class="mr-2" v-b-modal.modal-hcc :disabled="selectedTokensLemmas.length == 0" v-if="hcc">
          Remove HCC
        </b-button>
        <b-button size="sm" variant="danger" class="mr-2" v-b-modal.modal-hcc :disabled="Object.keys(selectedHCCs).length == 0" v-else>
          Remove HCC(s)
        </b-button>
      </span>
      <b-button size="sm" variant="primary" @click="selectAll" class="mr-1">
        Select all
      </b-button>
      <b-button size="sm" variant="primary" @click="deselectAll" class="">
        Deselect all
      </b-button>
      <span class="ml-2 mt-2">Selected {{ selectedTokensLemmas.length }} tokens</span>
    </div>
    <table class="table" id="datatable-results">
      <thead>
        <tr class="filtering" v-if="config.editable">
          <th v-for="(column, index) in columns" :key="`datatable-results-th-filter-${column.key}`" :style=" index < 5 ? 'position: sticky;left: 0;' : ''">
            <input
              v-if="column.searchable"
              v-model="filters[index]"
              type="text"
              class="form-control form-control-sm"
              :placeholder="column.name"
            />
            <span v-else>-</span>
          </th>
        </tr>
        <tr>
          <th v-for="column in columns" :key="`datatable-results-th-name-${column.key}`" v-html="column.name"></th>
        </tr>
      </thead>
      <tbody>
        <!-- <tr v-for="row in tableData" :key="`datatable-results-tr-${row.tokenId}`" @click="clickToken(row.tokenId, row.hccs)" :data-id="row.tokenId" :class="selectClass(row.tokenId)">
          <td :key="`datatable-results-td-data-${trIndex[row.tokenId]}`">
            <span v-if="selectedTokensLemmas.includes(row.tokenId)">
              <b-icon-check-circle-fill></b-icon-check-circle-fill>
            </span>
            <span v-else>
              <b-icon-circle></b-icon-circle>
            </span>
          </td>
          <td>
            <router-link
              v-html="row.editionSigle"
              :to="{name: 'DocumentDetails', params: {id: row.documentId}}"
              target="_blank"
            ></router-link>
          </td>
          <td>
            {{ row.division }}
          </td>
          <td>
            {{ row.date }}
          </td>
          <td>
            {{ row.redactor }}
          </td>
          <td>
            {{ row.languages }}
          </td>
          <td>
            {{ row.scripta }}
          </td>
          <td>
            <b-badge v-for="lemma in row.lemmas" :key="`res-table-lemma-${lemma.id}`" href="#" class="mr-1" variant="secondary">{{lemma.form}} ({{lemma.language_code}})</b-badge>
          </td>
          <!- - <td>
            {{ row.lemmas.map(lemma => `${lemma.form} (${lemma.language_code})`).join(', ') }}
          </td> - ->
          <td v-if="config.editable">
            <b-badge v-for="hcc in row.hccs" :key="`res-table-hcc-${hcc.hcc_id}`" href="#" class="mr-1" variant="secondary">{{hcc.shortname}}</b-badge>
          </td>
          <!- - <td>
            {{ row.hccs.map(hcc => hcc.shortname).join(', ') }}
          </td> - ->
          <td>
            <span class="nowrap text-bold" v-html="row.form"></span>
          </td>
          <td class="text-right">
            <span class="nowrap" v-html="row.left"></span>
          </td>
          <td>
            <span class="nowrap text-bold" v-html="row.form"></span>
          </td>
          <td>
            <span class="nowrap" v-html="row.right"></span>
          </td>
        </tr> -->
      </tbody>
    </table>

    <b-modal
      centered
      id="modal-tagging"
      title="Tagging"
      size="xl"
      @ok="assignTokensToHCC"
    >
      <HCCAutocomplete ref="hccRef" />
    </b-modal>

    <b-modal
      centered
      id="modal-tagging-replace"
      title="Tagging"
      size="xl"
      @ok="replaceHCC"
      v-if="hcc"
    >
      <HCCAutocomplete ref="hccRefReplace" :head="hcc.head" />
    </b-modal>

    <b-modal
      centered
      id="modal-lemmatize"
      title="Lemmatize"
      size="lg"
      @ok="assignTokensToLemma"
    >
      <LemmaSelect ref="lemmaSelectRef" />
    </b-modal>

    <div class="hcc-float" v-if="config.editable">
      <b-modal
        id="modal-hcc"
        title="Select HCCs to remove"
        ok-title="remove"
        size="md"
        v-if="! this.checkInHCCdetails()"
        @ok="removeTag"
      >
        Click on a HCC to discard it from the HCCs to be removed from the tokens (i.e. only HCCs visible in this dialog will be removed). <br /><br />
        <b-badge href="#"
          variant="info"
          class="mr-1"
          v-for="hcc in selectedHCCs"
          :key="'modal-22-' + hcc.hcc_id"
          @click="removeSelected(hcc)"
        >
          <span v-html="hcc.shortname"></span>
          <!-- <b-icon icon="trash" aria-hidden="true" class="ml-1"></b-icon> -->
        </b-badge>
      </b-modal>
      <b-modal
        id="modal-hcc"
        title="Remove HCC"
        ok-title="remove"
        size="md"
        v-else
        @ok="removeTag"
      >
        The HCC <font style="background-color:#BDEFE1;bold">{{ this.hcc.code }}</font> will be removed from the selected Token(s).<br />If there are other HCCs attached to the Token(s), they will not be removed.
      </b-modal>
    </div>

  </div>
</template>

<style scoped>
* >>> .nowrap {
  white-space: nowrap;
  font-family: 'Courier New', Courier, monospace;
  font-size: 13px;
}

.hcc-float {
  position: fixed;
  bottom: 15px;
  left: 20px;
  background-color: #bebebe9a;
  padding: 3px 8px 5px;
  border-radius: 3px;
}

* >>> table tbody tr.selected td {
  background-color: rgb(4 123 254 / 8%);
}

table.dataTable > thead .sorting_disabled:before,
table.dataTable > thead .sorting_disabled:after {
  content: '' !important;
}

* >>> table.dataTable tr td,
* >>> table.dataTable tr th {
  background-color: #fff;
}

* >>> table.dataTable tr th:nth-child(-n+4),
* >>> table.dataTable tr td:nth-child(-n+4){
  z-index: 1000;
}

table.dataTable > tbody tr {
  cursor: pointer;
}

table tr.filtering th {
  padding-right: 0 !important;
}

</style>

<script>
import LemmaSelect from "@/components/Lemma/LemmaSelect.vue"
import HCCAutocomplete from "@/components/Scripta/HCCAutocomplete.vue"
import config from '@/config.js'

import $ from 'jquery'

import 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-fixedcolumns-bs4'
import 'datatables.net-plugins/pagination/input.js'
// import '@/utilities/datatables-pagination.js'
// import '@/utilities/datatables-pagination.css'

export default {
  name: "DMSOccurrencesTable",

  props: ["context", "type", "viewOnly", "hcc"],

  components: {
    LemmaSelect,
    HCCAutocomplete,
  },

  data() {
    let trIndex = {}
    if (this.context && this.context.results){
      trIndex = Object.fromEntries(this.context.results.map(token => [token.token_id, 0]))
    }
    let columns = config.editable ? [
        { key: 'selected', name: '-' },
        { key: 'editionSigle', name: this.$t('DMSOccTableEdition'), sortable: true, searchable: true },
        { key: 'division', name: this.$t('DMSOccTableDivision'), sortable: true, searchable: true },
        { key: 'date', name: this.$t('DMSOccTableDate'), sortable: true, searchable: true },
        { key: 'redactor', name: this.$t('DMSOccTableScriptorium'), sortable: true, searchable: true },
        { key: 'languages', name: this.$t('DMSOccTableLanguage'), sortable: true, searchable: true },
        { key: 'scripta', name: this.$t('DMSOccTableScriptae'), sortable: true, searchable: true },
        { key: 'lemmas', name: this.$t('DMSOccTableLemmata'), sortable: true, searchable: true },
        { key: 'hccs', name: this.$t('DMSOccTableHCCs'), sortable: true, searchable: true },
        { key: 'form1', name: this.$t('DMSOccTableForm'), sortable: true, searchable: true },
        { key: 'left', name: this.$t('DMSOccTableLeft'), sortable: true, searchable: true },
        { key: 'form', name: this.$t('DMSOccTableForm'), sortable: true, searchable: true },
        { key: 'right', name: this.$t('DMSOccTableRight'), sortable: true, searchable: true },
      ] : [
        { key: 'selected', name: '-' },
        { key: 'editionSigle', name: this.$t('DMSOccTableEdition'), sortable: true, searchable: true },
        { key: 'division', name: this.$t('DMSOccTableDivision'), sortable: true, searchable: true },
        { key: 'date', name: this.$t('DMSOccTableDate'), sortable: true, searchable: true },
        { key: 'redactor', name: this.$t('DMSOccTableScriptorium'), sortable: true, searchable: true },
        { key: 'languages', name: this.$t('DMSOccTableLanguage'), sortable: true, searchable: true },
        { key: 'scripta', name: this.$t('DMSOccTableScriptae'), sortable: true, searchable: true },
        { key: 'lemmas', name: this.$t('DMSOccTableLemmata'), sortable: true, searchable: true },
        { key: 'form1', name: this.$t('DMSOccTableForm'), sortable: true, searchable: true },
        { key: 'left', name: this.$t('DMSOccTableLeft'), sortable: true, searchable: true },
        { key: 'form', name: this.$t('DMSOccTableForm'), sortable: true, searchable: true },
        { key: 'right', name: this.$t('DMSOccTableRight'), sortable: true, searchable: true },
      ]
    return {
      config: config,
      selectedTokensLemmas: [],
      allSelectedHCCs: [],
      selectedHCCs: {},
      trIndex: trIndex,
      timer: null,
      filters: Array(columns.length).fill(""),
      columns: columns,
    };
  },

  computed: {
    tableData() {
      let retval = []
      if (this.context && this.context.results) {
        retval = this.context.results.map(row => {
          let _document = row.document_id ? this.context.documents[row.document_id] : null
          let editionSigle = _document ? _document.sigle_legam : ''
          let date = _document ? _document.date : ''
          let languages = row.lang ? row.lang.join(",") : ''
          let lemmas = row.lemma_id ? row.lemma_id.map(lemma_id => this.context.lemmata[lemma_id].form) : []
          let hccs = row.hcc_id ? row.hcc_id.map(hcc_id => this.context.hccs[hcc_id].shortname) : []
          return {
            editionSigle: editionSigle,
            documentId: row.document_id,
            date: date,
            division: row.division,
            redactor: _document && _document.scriptorium ? _document.scriptorium : '-',
            languages: languages,
            scripta: _document && _document.scripta ? _document.scripta : '-',
            lemmas: lemmas.map(lemma => `${lemma.form} -- ${lemma.language_code}`).join(", "),
            hccs: hccs,
            left: row.left,
            form: row.form,
            form1: row.form,
            right: row.right,
            tokenId: row.token_id
          }
        })
      }
      return retval
    }
  },

  unmounted() {
    if (this.table) {
      this.table.destroy()
    }
  },

  mounted() {
    // setTimeout(() => {
    // $('table#datatable-results').DataTable().destroy()
    this.table = $('table#datatable-results').DataTable({
      language: {
        info: "_START_-_END_ of _TOTAL_",
        infoFiltered: " - filtered from _MAX_"
      },
      lengthMenu: [25, 50, 100, 200],
      dom: "rlt<'row'<'col-sm-12 col-md-4'i><'col-sm-12 col-md-8'p>>",
      pageLength: 100,
      pagingType: "input",
      stateSave: true,
      scrollX: true,
      fixedColumns:   {
        left: 4,
      },
      createdRow: function (row, data) {
        $(row).attr("data-id", data.tokenId);
        // if (data.articleId == null){
        //   $(row).addClass('selectable')
        // }
        // if (that.selectedRows) {
        //   let selectedIds = that.selectedRows.map((_row) => _row.id);
        //   if (selectedIds.includes(data.id)) {
        //     $(row).addClass("selected");
        //   }
        // }
        // if (that.trFunction) {
        //   that.trFunction(row, data);
        // }
      },
      data: this.tableData,
      columns: this.columns.map(column => {
        return {
          data: column.key,
          defaultContent: '',
          orderable: (column.sortable === true ? true : false),
          createdCell: (td, cellData, row, rowIndex, colIndex) => {
            if (this.columns[colIndex].key == "selected") {
              td.innerHTML = '<label><input type="checkbox" class="selectBox"></label>'
            }

            if (["form", "form1"].includes(this.columns[colIndex].key)) {
              // td.innerHTML = `<span class="nowrap text-bold">${row.form}</span>`
              td.innerHTML = `<span class="nowrap text-bold">${cellData}</span>`
            }

            if (["left", "right"].includes(this.columns[colIndex].key)) {
              td.innerHTML = `<span class="nowrap">${cellData}</span>`
            }

            // We need edition id
            if (this.columns[colIndex].key == "editionSigle") {
              td.innerHTML = `<a target="_blank" href="#/document/${row.documentId}">${cellData}</a>`
            }
          }
        }
      })
    })

    let that  = this;

    $("table tbody").on("click", "input", function () {
      let clickedRow = this.parentNode.parentNode.parentNode
      let rowData = that.table.row(clickedRow).data()
      let hccs = that.context.results.filter(row => row.token_id == rowData.tokenId).map(row => row.hcc_id).flat().filter(n => n)

      if (that.selectedTokensLemmas.includes(rowData.tokenId)){
        that.selectedTokensLemmas = that.selectedTokensLemmas.filter(_tokenId => _tokenId != rowData.tokenId)
        hccs.map(hcc => {
          let idx = that.allSelectedHCCs.findIndex(i => i == hcc)
          if (idx+1) {
            that.allSelectedHCCs.splice(idx, 1)
          }
        })
      }
      else {
        that.selectedTokensLemmas.push(rowData.tokenId)
        that.allSelectedHCCs = that.allSelectedHCCs.concat(hccs)
      }

      // brute force: clear selected HCCs and rebuild from array containing only (duplicated) hcc_ids
      that.selectedHCCs = {}
      that.allSelectedHCCs.map(hcc_id => that.selectedHCCs[hcc_id] = that.context.hccs[hcc_id])
    });
  },

  created() {
    // Remove selected row not in results
    if (this.context && this.context.results){
      this.filters = Array(this.columns.length).fill("")
      let tokenIds = null
      if (this.context.results) {
        tokenIds = this.context.results.map(token => token.token_id)
      }
      this.selectedTokensLemmas = this.$store.state.docediting.selectedTokensLemmas
      this.$store.state.docediting.selectedTokensLemmas = this.$store.state.docediting.selectedTokensLemmas.filter(
        tokenId => tokenIds.includes(tokenId)
      )
    }

    // setTimeout(() => {
    //   this.table = $('table#datatable-results').DataTable({
    //     language: {
    //       info: "_START_-_END_ of _TOTAL_",
    //       infoFiltered: " - filtered from _MAX_"
    //     },
    //     lengthMenu: [25, 50, 100, 200],
    //     dom: "rlt<'row'<'col-sm-12 col-md-4'i><'col-sm-12 col-md-8'p>>",
    //     pageLength: 100,
    //     pagingType: "input",
    //     stateSave: true,
    //     scrollX: true,
    //     fixedColumns:   {
    //       left: 4,
    //     },
    //     columns: this.columns.map(column => {
    //       return {
    //         data: column.name,
    //         defaultContent: '',
    //         orderable: (column.sortable === true ? true : false)
    //       }
    //     })
    //   })
    // }, 3000)
  },

  watch: {
    selectedTokensLemmas() {
      this.$store.state.docediting.selectedTokensLemmas = this.selectedTokensLemmas
    },
    filters() {
      this.filterDataTablesData()
    }
  },

  methods: {
    filterDataTablesData() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.table) {
          this.filters.forEach((filter, index) => {
            if (this.table.column(index).search() !== filter) {
              this.table.column(index).search(filter, true, false);
            }
          });
          this.table.draw();
        }
      }, 500);
    },
    downloadCsvAll() {
      const rows = [
        ["Edition", "Division", "Date", "Redactor", "Language", "Scripta", "Lemmas", "HCCs", "Form", "Left Context", "Right Context"],
        ...this.tableData.map(row => [
          row.editionSigle,
          row.date,
          row.division,
          row.redactor,
          row.languages,
          row.scripta,
          row.lemmas.map(lemma => `${lemma.form} (${lemma.language_code})`).join('; '),
          row.hccs.map(hcc => hcc.shortname).join('; '),
          row.form,
          row.left,
          row.right
        ])
      ]

      // let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join("\t")).join("\n");
      let csvContent = "data:text/csv;charset=utf-8," + this.toCSV(rows);
      var encodedUri = encodeURI(csvContent);
      window.open(encodedUri);
    },
    toCSV(table) {
      return table
        .map(row => row.map(cell => {
            // We remove blanks and check if the column contains
            // other whitespace,`,` or `"`.
            // In that case, we need to quote the column.
            cell = cell ? cell.toString().replace(/#/g, ".") : ''
            if (cell.replace(/ /g, '').match(/[\s,"]/)) {
                return '"' + cell.replace(/"/g, '""') + '"';
            }
            return cell;
          }).join(',')
        )
        .join('\n');
    },
    downloadCsvSelected() {
      const rows = [
        ["Edition", "Division", "Date", "Redactor", "Language", "Scripta", "Lemmas", "HCCs", "Form", "Left Context", "Right Context"],
        ...this.tableData.filter(row => this.selectedTokensLemmas.includes(row.tokenId)).map(row => [
          row.editionSigle,
          row.date,
          row.division,
          row.redactor,
          row.languages,
          row.scripta,
          row.lemmas.map(lemma => `${lemma.form} (${lemma.language_code})`).join('; '),
          row.hccs.map(hcc => hcc.shortname).join('; '),
          row.form,
          row.left,
          row.right
        ])
      ]

      // let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join("\t")).join("\n");
      let csvContent = "data:text/csv;charset=utf-8," + this.toCSV(rows);
      let encodedUri = encodeURI(csvContent);
      let filename = `gallrom_export_${(new Date()).toISOString()}.csv`;
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    },
    clickToken(tokenId, hccs) {
      if (this.selectedTokensLemmas.includes(tokenId)){
        this.selectedTokensLemmas = this.selectedTokensLemmas.filter(_tokenId => _tokenId != tokenId)
        hccs.map(hcc => this.allSelectedHCCs.splice(this.allSelectedHCCs.indexOf(hcc.hcc_id), 1))
        this.selectedHCCs = Object.fromEntries(this.allSelectedHCCs.map(hcc_id => [hcc_id, this.selectedHCCs[hcc_id]]));
        // hccs.map(hcc => delete this.selectedHCCs[hcc.hcc_id])
      }
      else {
        this.selectedTokensLemmas.push(tokenId)
        hccs.map(hcc => {
          this.allSelectedHCCs.push(hcc.hcc_id);
          this.selectedHCCs[hcc.hcc_id] = this.context.hccs[hcc.hcc_id]
        })
      }
      this.trIndex[tokenId]++
    },
    selectClass(tokenId) {
      let retval = []
      if (this.selectedTokensLemmas.includes(tokenId)){
        retval.push("selected")
      }
      return retval
    },
    redrawTable() {
      this.table.clear()
      this.table.rows.add(this.tableData).draw();
    },
    selectAll() {
      this.selectedTokensLemmas = this.context.results.map(token => token.token_id)
      this.allSelectedHCCs = []
      this.context.results
        .filter(token => token.hcc_id)
        .map(token => token.hcc_id.map(hcc_id => {
              this.selectedHCCs[hcc_id] = this.context.hccs[hcc_id];
              this.allSelectedHCCs.push(hcc_id)
            }))
      this.context.results.forEach(token => {
        this.trIndex[token.token_id]++
      })
      $(".selectBox").each(function() {
        $(this).prop("checked", true)
      })
    },
    deselectAll() {
      this.selectedTokensLemmas = []
      this.selectedHCCs = {}
      this.allSelectedHCCs = []
      this.context.results.forEach(token => {
        this.trIndex[token.token_id]++
      })
      $(".selectBox").each(function() {
        $(this).prop("checked", false)
      })
    },
    removeLemma() {
      this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
        if (value){
          this.$store.dispatch("removeAssignedLemmas", {
            tokenIds: this.selectedTokensLemmas,
          }).then(() => {
            this.selectedTokensLemmas = []
            this.$emit('update')
          });
        }
      });
    },
    removeTag() {
      this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
        if (value){
          this.$store.dispatch("removeAssignedHCCs", {
            tokenIds: this.selectedTokensLemmas,
            hccIds: this.checkInHCCdetails() ? [ this.hcc.id ] : Object.keys(this.selectedHCCs),
          }).then(() => {
            this.selectedTokensLemmas = []
            this.selectedHCCs = {}
            this.$emit('update')
          });
        }
      });
    },

    checkInHCCdetails() {
      // dirty hack: if in HCC Details, 'selectedHCCs' is not filled correctly,
      // so we test for that to determine this fact...
      if (Object.keys(this.selectedHCCs).length == 1 && Object.keys(this.selectedHCCs)[0] == "undefined") {
        return true
      } else {
        return false
      }
    },

    removeSelected(item) {
      this.selectedHCCs = Object.fromEntries(Object.entries(this.selectedHCCs)
                                                .filter(_item => item.hcc_id != _item[0]))
    },
    assignTokensToLemma() {
      if (this.$refs.lemmaSelectRef.lemmaObj){
        this.$store.dispatch("assignTokensToLemma", {
          tokenIds: this.selectedTokensLemmas,
          lemmaId: this.$refs.lemmaSelectRef.lemmaObj.id,
        }).then(() => {
          this.$emit('update')
        });
      }
    },
    assignTokensToHCC() {
      if (this.$refs.hccRef.hccObj){
        this.$store.dispatch("assignTokensToHCC", {
          tokenIds: this.selectedTokensLemmas,
          hccId: this.$refs.hccRef.hccObj.id
        }).then(() => {
          this.selectedTokensLemmas = []
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Tokens assigned to HCC`
          })
          this.$emit('update')
        });
      }
    },
    replaceHCC() {
      if (this.$refs.hccRefReplace.hccObj){
        this.$store.dispatch("replaceHCC", {
          tokenIds: this.selectedTokensLemmas,
          oldHccId: this.hcc.id,
          hccId: this.$refs.hccRefReplace.hccObj.id
        }).then(() => {
          this.selectedTokensLemmas = []
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Tokens moved to new HCC`
          })
          this.$emit('update')
        });
      }
    },
  },
};
</script>
